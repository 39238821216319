import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    redirect: {name: 'report'}
  },
  {
    path: '/report',
    component: () => import('@/views/report/Index'),
    children: [
      {
        path: '',
        name: 'report',
        component: () => import('@/views/report/List'),
        meta: {
          title: 'КЗ - Отчётность'
        },
      },
    ]
  },
  {
    path: '/users',
    component: () => import('@/views/user/Index'),
    children: [
      {
        path: '',
        name: 'users',
        component: () => import('@/views/user/List'),
        meta: {
          title: 'ЕСУ РСМ - Пользователи'
        },
      },
      {
        path: 'create',
        name: 'users-create',
        component: () => import('@/views/user/Create'),
        meta: {
          title: 'ЕСУ РСМ | Пользователи - Добавление'
        },
      },
      {
        path: ':id',
        name: 'users-edit',
        component: () => import('@/views/user/Edit'),
        meta: {
          title: 'ЕСУ РСМ | Пользователи - Редактирование'
        },
      },
    ]
  },
  {
    path: '/agreement',
    component: () => import('@/views/agreement/Index'),
    children: [
      {
        path: '',
        name: 'agreement',
        component: () => import('@/views/agreement/List'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Соглашения о сотрудничестве с партнерами'
        },
      },
      {
        path: 'create',
        name: 'agreement-create',
        component: () => import('@/views/agreement/Create'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Соглашения о сотрудничестве с партнерами - Добавление'
        },
      },
      {
        path: 'stat',
        name: 'agreement-stat',
        component: () => import('@/views/agreement/Stat'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Соглашения о сотрудничестве с партнерами - Статистика'
        },
      },
      {
        path: ':id',
        name: 'agreement-edit',
        component: () => import('@/views/agreement/Edit'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Соглашения о сотрудничестве с партнерами - Редактирование'
        },
      },
    ]
  },
  {
    path: '/balance',
    component: () => import('@/views/balance/Index'),
    children: [
      {
        path: '',
        name: 'balance',
        component: () => import('@/views/balance/List'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Бухгалтерский баланс'
        },
      },
      {
        path: 'create',
        name: 'balance-create',
        component: () => import('@/views/balance/Create'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Бухгалтерский баланс - Добавление'
        },
      },
      {
        path: 'stat',
        name: 'balance-stat',
        component: () => import('@/views/balance/Stat'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Бухгалтерский баланс - Статистика'
        },
      },
      {
        path: ':id',
        name: 'balance-edit',
        component: () => import('@/views/balance/Edit'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Бухгалтерский баланс - Редактирование'
        },
      },
    ]
  },
  {
    path: '/justice',
    component: () => import('@/views/justice/Index'),
    children: [
      {
        path: '',
        name: 'justice',
        component: () => import('@/views/justice/List'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Отчетность в территориальные органы Минюста РФ'
        },
      },
      {
        path: 'create',
        name: 'justice-create',
        component: () => import('@/views/justice/Create'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Отчетность в территориальные органы Минюста РФ - Добавление'
        },
      },
      {
        path: 'stat',
        name: 'justice-stat',
        component: () => import('@/views/justice/Stat'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Отчетность в территориальные органы Минюста РФ - Статистика'
        },
      },
      {
        path: ':id',
        name: 'justice-edit',
        component: () => import('@/views/justice/Edit'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Отчетность в территориальные органы Минюста РФ - Редактирование'
        },
      },
    ]
  },
  {
    path: '/info',
    component: () => import('@/views/info/Index'),
    children: [
      {
        path: 'calendar/create',
        name: 'calendar-create',
        component: () => import('@/views/info/CalendarCreate'),
        meta: {
          title: 'ЕСУ РСМ | Производственный календарь - Добавление'
        },
      },
      {
        path: '',
        name: 'info-layout',
        component: () => import('@/views/info/Info'),
        redirect: { name: 'events' },
        children: [
          {
            path: 'events',
            name: 'events',
            component: () => import('@/views/info/EventsList'),
            meta: {
              title: 'ЕСУ РСМ - Отчетность | Общий план мероприятий'
            },
          },
          {
            path: 'calendar',
            name: 'calendar',
            component: () => import('@/views/info/CalendarList'),
            meta: {
              title: 'ЕСУ РСМ - Производственный календарь'
            },
          },
        ]
      },
      {
        path: 'events/:id',
        name: 'events-edit',
        component: () => import('@/views/info/EventsEdit'),
        meta: {
          title: 'ЕСУ РСМ | Общий план мероприятий - Просмотр'
        },
      },
      {
        path: 'calendar/:id',
        name: 'calendar-edit',
        component: () => import('@/views/info/CalendarEdit'),
        meta: {
          title: 'ЕСУ РСМ | Производственный календарь - Редактирование'
        },
      },
    ]
  },
  {
    path: '/event-plan',
    component: () => import('@/views/event-plan/Index'),
    children: [
      {
        path: '',
        name: 'event-plan',
        component: () => import('@/views/event-plan/List'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | План мероприятий РО'
        },
      },
      {
        path: 'create',
        name: 'event-plan-create',
        component: () => import('@/views/event-plan/Create'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | План мероприятий РО - Добавление'
        },
      },
      {
        path: 'stat',
        name: 'event-plan-stat',
        component: () => import('@/views/event-plan/Stat'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | План мероприятий РО - Статистика'
        },
      },
      {
        path: ':id',
        name: 'event-plan-edit',
        component: () => import('@/views/event-plan/Edit'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | План мероприятий РО - Редактирование'
        },
      },
    ]
  },
  {
    path: '/program',
    component: () => import('@/views/program/Index'),
    children: [
      {
        path: '',
        name: 'program',
        component: () => import('@/views/program/List'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Уведомление о реализации центральных программ и проектов'
        },
      },
      {
        path: 'create',
        name: 'program-create',
        component: () => import('@/views/program/Create'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Уведомление о реализации центральных программ и проектов - Добавление'
        },
      },
      {
        path: 'stat',
        name: 'program-stat',
        component: () => import('@/views/program/Stat'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Уведомление о реализации центральных программ и проектов - Статистика'
        },
      },
      {
        path: ':id',
        name: 'program-edit',
        component: () => import('@/views/program/Edit'),
        meta: {
          title: 'Отчетность | Уведомление о реализации центральных программ и проектов - Редактирование'
        },
      },
    ]
  },
  {
    path: '/school',
    component: () => import('@/views/school/Index'),
    children: [
      {
        path: '',
        name: 'school',
        component: () => import('@/views/school/List'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Кадровые школы'
        },
      },
      {
        path: 'create',
        name: 'school-create',
        component: () => import('@/views/school/Create'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Кадровые школы - Добавление'
        },
      },
      {
        path: 'stat',
        name: 'school-stat',
        component: () => import('@/views/school/Stat'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Кадровые школы - Статистика'
        },
      },
      {
        path: ':id',
        name: 'school-edit',
        component: () => import('@/views/school/Edit'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Кадровые школы - Редактирование'
        },
      },
    ]
  },
  {
    path: '/local',
    component: () => import('@/views/local/Index'),
    children: [
      {
        path: '',
        name: 'local',
        component: () => import('@/views/local/List'),
        meta: {
          title: 'ЕСУ РСМ - Местные организации'
        },
      },
      {
        path: 'create',
        name: 'local-create',
        component: () => import('@/views/local/Create'),
        meta: {
          title: 'ЕСУ РСМ - Местные организации - Добавление'
        },
      },
      {
        path: 'stat',
        name: 'local-stat',
        component: () => import('@/views/local/Stat'),
        meta: {
          title: 'ЕСУ РСМ - Местные организации - Статистика'
        },
      },
      {
        path: ':id',
        name: 'local-edit',
        component: () => import('@/views/local/Edit'),
        meta: {
          title: 'ЕСУ РСМ - Местные организации - Редактирование'
        },
      },
    ]
  },
  {
    path: '/primary',
    component: () => import('@/views/primary/Index'),
    children: [
      {
        path: '',
        name: 'primary',
        component: () => import('@/views/primary/List'),
        meta: {
          title: 'ЕСУ РСМ - Первичные группы'
        },
      },
      {
        path: 'create',
        name: 'primary-create',
        component: () => import('@/views/primary/Create'),
        meta: {
          title: 'ЕСУ РСМ - Первичные группы - Добавление'
        },
      },
      {
        path: 'stat',
        name: 'primary-stat',
        component: () => import('@/views/primary/Stat'),
        meta: {
          title: 'ЕСУ РСМ - Первичные группы - Статистика'
        },
      },
      {
        path: ':id',
        name: 'primary-edit',
        component: () => import('@/views/primary/Edit'),
        meta: {
          title: 'ЕСУ РСМ - Первичные группы - Редактирование'
        },
      },
    ]
  },
  {
    path: '/head',
    name: 'head',
    component: () => import('@/views/head/Form'),
    meta: {
      title: 'ЕСУ РСМ - Отчетность | Анкета руководителя'
    },
  },
  {
    path: '/head/stat',
    name: 'head-stat',
    component: () => import('@/views/head/Stat'),
    meta: {
      title: 'ЕСУ РСМ - Отчетность | Анкета руководителя - Статистика'
    },
  },
  {
    path: '/passport',
    component: () => import('@/views/passport/Index'),
    children: [
      {
        path: '',
        name: 'passport',
        component: () => import('@/views/passport/Passport'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО'
        },
      },
      {
        path: 'organization',
        name: 'passport-org',
        component: () => import('@/views/passport/Organization'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Общие сведения об организации'
        },
      },
      {
        path: 'head',
        name: 'passport-head',
        component: () => import('@/views/passport/HeadList'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Сведения о руководящих органах организации'
        },
      },
      {
        path: 'head/create',
        name: 'passport-head-create',
        component: () => import('@/views/passport/HeadCreate'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Сведения о руководящих органах организации - Добавление'
        },
      },
      {
        path: 'head/:id',
        name: 'passport-head-edit',
        component: () => import('@/views/passport/HeadEdit'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Сведения о руководящих органах организации - Редактирование'
        },
      },
      {
        path: 'control',
        name: 'passport-control',
        component: () => import('@/views/passport/Control'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Сведения о контрольно-ревизионном органе организации'
        },
      },
      {
        path: 'workers',
        name: 'passport-workers',
        component: () => import('@/views/passport/WorkersList'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Работники аппарата региональной организации РСМ'
        },
      },
      {
        path: 'workers/create',
        name: 'passport-workers-create',
        component: () => import('@/views/passport/WorkersCreate'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Работники аппарата региональной организации РСМ - Добавление'
        },
      },
      {
        path: 'workers/:id',
        name: 'passport-workers-edit',
        component: () => import('@/views/passport/WorkersEdit'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Работники аппарата региональной организации РСМ - Редактирование'
        },
      },
      {
        path: 'immovables',
        name: 'passport-immovables',
        component: () => import('@/views/passport/ImmovablesList'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Сведения о недвижимом имуществе, находящимся в собственности региональной организации РСМ, аренде'
        },
      },
      {
        path: 'immovables-stat',
        name: 'passport-immovables-stat',
        component: () => import('@/views/passport/ImmovablesStat'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Сведения о недвижимом имуществе, находящимся в собственности региональной организации РСМ, аренде'
        },
      },
      {
        path: 'immovables/create',
        name: 'passport-immovables-create',
        component: () => import('@/views/passport/ImmovablesCreate'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Сведения о недвижимом имуществе, находящимся в собственности региональной организации РСМ, аренде - Добавление'
        },
      },
      {
        path: 'immovables/:id',
        name: 'passport-immovables-edit',
        component: () => import('@/views/passport/ImmovablesEdit'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Сведения о недвижимом имуществе, находящимся в собственности региональной организации РСМ, аренде - Редактирование'
        },
      },
    ]
  },
  {
    path: '/journal',
    name: 'journal',
    component: () => import('@/views/journal/Index'),
    children: [
      {
        path: 'import',
        name: 'journal-import',
        component: () => import('@/views/journal/Import'),
        meta: {
          title: 'ЕСУ РСМ - Журнал учёта | Импорт членов РСМnt'
        },
      },
      {
        path: 'list',
        name: 'journal-list',
        component: () => import('@/views/journal/List'),
        meta: {
          title: 'ЕСУ РСМ - Журнал учета | Список членов РСМ'
        },
      },
      {
        path: 'request-list',
        name: 'journal-request-list',
        component: () => import('@/views/journal/RequestList'),
        meta: {
          title: 'ЕСУ РСМ - Журнал учета | Заявки на вступление'
        },
      },
      {
        path: 'moving',
        name: 'journal-moving-list',
        component: () => import('@/views/journal/MovingIndex'),
        redirect: { name: 'journal-moving-list-inner' },
        meta: {
          title: 'ЕСУ РСМ - Журнал учёта | Перемещение'
        },
        children: [
          {
            path: 'outer',
            name: 'journal-moving-list-outer',
            component: () => import('@/views/journal/MovingList'),
            meta: {
              title: 'ЕСУ РСМ - Журнал учёта | Перемещение'
            },
          },
          {
            path: 'inner',
            name: 'journal-moving-list-inner',
            component: () => import('@/views/journal/MovingListInner'),
            meta: {
              title: 'ЕСУ РСМ - Журнал учёта | Перемещение'
            },
          },
        ]
      },
      {
        path: 'refuse',
        name: 'journal-refuse-list',
        component: () => import('@/views/journal/RefuseList'),
        meta: {
          title: 'ЕСУ РСМ - Журнал учёта | Прекращение учёта'
        },
      },
      {
        path: 'continue',
        name: 'journal-continue-list',
        component: () => import('@/views/journal/ContinueList'),
        meta: {
          title: 'ЕСУ РСМ - Журнал учёта | Продление учёта'
        },
      },
      {
        path: 'create',
        name: 'journal-create',
        component: () => import('@/views/journal/Create'),
        meta: {
          title: 'ЕСУ РСМ - Журнал учета | Список членов РСМ - Добавление члена РСМ'
        },
      },
      {
        path: 'moving-create',
        name: 'journal-moving-create',
        component: () => import('@/views/journal/MovingCreate'),
        meta: {
          title: 'ЕСУ РСМ - Журнал учёта | Перемещение - Создание заявки'
        },
      },
      {
        path: 'refuse-create',
        name: 'journal-refuse-create',
        component: () => import('@/views/journal/RefuseCreate'),
        meta: {
          title: 'ЕСУ РСМ - Журнал учёта | Прекращение учёта - Создание заявки'
        },
      },
      {
        path: 'continue-create',
        name: 'journal-continue-create',
        component: () => import('@/views/journal/ContinueCreate'),
        meta: {
          title: 'ЕСУ РСМ - Журнал учёта | Продление учёта - Создание заявки'
        },
      },
      {
        path: ':id',
        name: 'journal-edit',
        component: () => import('@/views/journal/Edit'),
        meta: {
          title: 'ЕСУ РСМ - Журнал учета | Список членов РСМ - Редактирование члена РСМ'
        },
      },
    ]
  },
  {
    path: '/grant',
    component: () => import('@/views/grant/Index'),
    redirect: { name: 'grant-participation' },
    children: [
      {
        path: 'stat',
        name: 'grant-stat',
        component: () => import('@/views/grant/Stat'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Грантовые конкурсы, субсидии - Статистика'
        },
      },
      {
        path: 'participation-create',
        name: 'grant-create-participation',
        component: () => import('@/views/grant/CreateParticipation'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Грантовые конкурсы, субсидии - Добавление'
        },
      },
      {
        path: 'victory-create',
        name: 'grant-create-victory',
        component: () => import('@/views/grant/CreateVictory'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Грантовые конкурсы, субсидии - Добавление'
        },
      },
      {
        path: '',
        name: 'grant',
        component: () => import('@/views/grant/Grant'),
        redirect: { name: 'grant-participation' },
        children: [
          {
            path: 'participation',
            name: 'grant-participation',
            component: () => import('@/views/grant/ParticipationList'),
            meta: {
              title: 'ЕСУ РСМ - Отчетность | Грантовые конкурсы, субсидии - Участие'
            },
          },
          {
            path: 'victory',
            name: 'grant-victory',
            component: () => import('@/views/grant/VictoryList'),
            meta: {
              title: 'ЕСУ РСМ - Отчетность | Грантовые конкурсы, субсидии - Победы'
            },
          },
        ]
      },
      {
        path: 'participation/:id',
        name: 'grant-participation-edit',
        component: () => import('@/views/grant/EditParticipation'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Грантовые конкурсы, субсидии - Редактирование'
        },
      },
      {
        path: 'victory/:id',
        name: 'grant-victory-edit',
        component: () => import('@/views/grant/EditVictory'),
        meta: {
          title: 'КЗ - Редактирование гранта'
        },
      },
    ]
  },
  {
    path: '/meeting',
    component: () => import('@/views/meeting/Index'),
    redirect: { name: 'meeting-top' },
    children: [
      {
        path: 'stat',
        name: 'meeting-stat',
        component: () => import('@/views/meeting/Stat'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Заседания высших органов - Статистика'
        },
      },
      {
        path: 'top-create',
        name: 'meeting-top-create',
        component: () => import('@/views/meeting/CreateTop'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Заседания высших органов - Добавление'
        },
      },
      {
        path: 'manage-create',
        name: 'meeting-manage-create',
        component: () => import('@/views/meeting/CreateManage'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Заседания руководящих органов - Добавление'
        },
      },
      {
        path: '',
        name: 'meeting',
        component: () => import('@/views/meeting/Meeting'),
        redirect: { name: 'meeting-top' },
        children: [
          {
            path: 'top',
            name: 'meeting-top',
            component: () => import('@/views/meeting/TopList'),
            meta: {
              title: 'ЕСУ РСМ - Отчетность | Заседания высших органов'
            },
          },
          {
            path: 'manage',
            name: 'meeting-manage',
            component: () => import('@/views/meeting/ManageList'),
            meta: {
              title: 'ЕСУ РСМ - Отчетность | Заседания руководящих органов'
            },
          },
        ]
      },
      {
        path: 'top/:id',
        name: 'meeting-top-edit',
        component: () => import('@/views/meeting/EditTop'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Заседания высших органов - Редактирование'
        },
      },
      {
        path: 'manage/:id',
        name: 'meeting-manage-edit',
        component: () => import('@/views/meeting/EditManage'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Заседания руководящих органов - Редактирование'
        },
      },
    ]
  },
  {
    path: '/information',
    component: () => import('@/views/information/Index'),
    redirect: { name: 'information' },
    children: [
      {
        path: 'stat',
        name: 'information-stat',
        component: () => import('@/views/information/Stat'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Информационное обеспечение деятельности - Статистика'
        },
      },
      {
        path: 'official-create',
        name: 'information-official-create',
        component: () => import('@/views/information/OfficialCreate'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Информационное обеспечение деятельности - Добавление'
        },
      },
      {
        path: 'federal-create',
        name: 'information-federal-create',
        component: () => import('@/views/information/FederalCreate'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Информационное обеспечение деятельности - Добавление'
        },
      },
      {
        path: 'regional-create',
        name: 'information-regional-create',
        component: () => import('@/views/information/RegionalCreate'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Информационное обеспечение деятельности - Добавление'
        },
      },
      {
        path: 'education-create',
        name: 'information-education-create',
        component: () => import('@/views/information/EducationCreate'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Информационное обеспечение деятельности - Добавление'
        },
      },
      {
        path: '',
        name: 'information',
        component: () => import('@/views/information/Information'),
        redirect: { name: 'information-official' },
        children: [
          {
            path: 'official',
            name: 'information-official',
            component: () => import('@/views/information/OfficialList'),
            meta: {
              title: 'ЕСУ РСМ - Отчетность | Информационное обеспечение деятельности - Официальные группы'
            },
          },
          {
            path: 'federal',
            name: 'information-federal',
            component: () => import('@/views/information/FederalList'),
            meta: {
              title: 'ЕСУ РСМ - Отчетность | Информационное обеспечение деятельности - Федеральные СМИ'
            },
          },
          {
            path: 'regional',
            name: 'information-regional',
            component: () => import('@/views/information/RegionalList'),
            meta: {
              title: 'ЕСУ РСМ - Отчетность | Информационное обеспечение деятельности - Региональные СМИ'
            },
          },
          {
            path: 'education',
            name: 'information-education',
            component: () => import('@/views/information/EducationList'),
            meta: {
              title: 'ЕСУ РСМ - Отчетность | Информационное обеспечение деятельности - Учебные заведения'
            },
          },
        ]
      },
      {
        path: 'official/:id',
        name: 'information-official-edit',
        component: () => import('@/views/information/OfficialEdit'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Информационное обеспечение деятельности - Редактирование'
        },
      },
      {
        path: 'federal/:id',
        name: 'information-federal-edit',
        component: () => import('@/views/information/FederalEdit'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Информационное обеспечение деятельности - Редактирование'
        },
      },
      {
        path: 'regional/:id',
        name: 'information-regional-edit',
        component: () => import('@/views/information/RegionalEdit'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Информационное обеспечение деятельности - Редактирование'
        },
      },
      {
        path: 'education/:id',
        name: 'information-education-edit',
        component: () => import('@/views/information/EducationEdit'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Информационное обеспечение деятельности - Редактирование'
        },
      },
    ]
  },
  {
    path: '/interaction',
    component: () => import('@/views/passport/Index'),
    children: [
      {
        path: '',
        name: 'interaction',
        component: () => import('@/views/interaction/Interaction'),
        meta: {
          title: 'ЕСУ РСМ - Журнал учёта | Прекращение учёта'
        },
      },
      {
        path: 'advisory',
        name: 'passport-interaction-advisory',
        component: () => import('@/views/passport/AdvisoryList'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Участие в советах и комиссиях'
        },
      },
      {
        path: 'advisory/create',
        name: 'passport-interaction-advisory-create',
        component: () => import('@/views/passport/AdvisoryCreate'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Участие в советах и комиссиях - Добавление'
        },
      },
      {
        path: 'advisory/:id',
        name: 'passport-interaction-advisory-edit',
        component: () => import('@/views/passport/AdvisoryEdit'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Участие в советах и комиссиях - Редактирование'
        },
      },
      {
        path: 'public',
        name: 'passport-interaction-public',
        component: () => import('@/views/passport/PublicList'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Участие в Общественных палатах региона, РФ'
        },
      },
      {
        path: 'public/create',
        name: 'passport-interaction-public-create',
        component: () => import('@/views/passport/PublicCreate'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Участие в Общественных палатах региона, РФ - Добавление'
        },
      },
      {
        path: 'public/:id',
        name: 'passport-interaction-public-edit',
        component: () => import('@/views/passport/PublicEdit'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Участие в Общественных палатах региона, РФ - Редактирование'
        },
      },
      {
        path: 'name',
        name: 'passport-interaction-name',
        component: () => import('@/views/passport/Name'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Региональный орган власти в сфере молодежной политики'
        },
      },
      {
        path: 'support',
        name: 'passport-interaction-support',
        component: () => import('@/views/passport/Support'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Формы поддержки РО РСМ'
        },
      },
      {
        path: 'partner',
        name: 'passport-interaction-partner',
        component: () => import('@/views/passport/PartnerList'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Основные партнеры организации'
        },
      },
      {
        path: 'partner/create',
        name: 'passport-interaction-partner-create',
        component: () => import('@/views/passport/PartnerCreate'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Основные партнеры организации - Добавление'
        },
      },
      {
        path: 'partner/:id',
        name: 'passport-interaction-partner-edit',
        component: () => import('@/views/passport/PartnerEdit'),
        meta: {
          title: 'ЕСУ РСМ - Отчетность | Паспорт РО - Основные партнеры организации - Редактирование'
        },
      },
    ]
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import('@/views/Statistics'),
    meta: {
      title: 'ЕСУ РСМ - Статистика'
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Profile'),
    meta: {
      title: 'КЗ - Профиль'
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login'),
    meta: {
      layout: 'welcome',
      title: 'ЕСУ РСМ - Авторизация'
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'public',
      title: 'CLUB RUY - Страница не найдена'
    }
  },
  {
    path: '*',
    redirect: { name: '404' }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem('accessToken');

  document.title = to.meta.title
  if (loggedIn && to.fullPath.includes('login')) {
    next({name: 'report'});
  } else {
    next()
  }
})

export default router
